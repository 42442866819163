class ImageHelper {
    static instance = null;
    static createInstance() {
        var object = new ImageHelper();
        return object;
    }

    static getInstance() {
        if (!ImageHelper.instance) {
            ImageHelper.instance = ImageHelper.createInstance();
        }
        return ImageHelper.instance;
    }

    constructor(){
    }

    loadImage(url) {
        /*return new Promise(function(resolve, reject) {
            var img = new Image()
            img.onload = function() {
                resolve(url)
            }
            img.onerror = function() {
                reject(url)
            }
            img.src = url
        })*/

        return new Promise(function(resolve, reject) {
            var img = new Image()
            img.onload = function() {
                resolve(url)
            }
            img.onerror = function(e) {
                reject(e)
            }
            img.src = url
        })
    }
}

const instance = ImageHelper.getInstance();
export default instance;